import axios from "axios";
import router from "@/router";
import { notify } from "@kyvg/vue3-notification";
import store from '../store'

//устанавливает дефолтный адрес бекенда для axios
axios.defaults.baseURL = location.hostname==='localhost'?'http://localhost:8081':process.env.VUE_APP_BASE_URL
//use cookie (for auth)
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

//добавляем токен авторизации, если есть, перед каждым запросом
axios.interceptors.request.use(function (config) {

  if (store.getters["auth/getToken"]) {
    config.headers['Authorization'] = 'Bearer ' + store.getters["auth/getToken"];
  }

  //передаем UUID выбранного проекта
  if (store.getters.getCurrentProject) {
    config.headers['X-Project'] = store.getters.getCurrentProject;
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});



//обработчик ошибок
axios.interceptors.response.use(
  function (response) {
    if (response.data.meta && response.data.meta.notify)
      notify({ type: "success", text: response.data.meta.notify });

    return response;
  },
  function (error) {
    const routeMeta = router.currentRoute._value.meta;
    const requiresAuth = !!Object.keys(routeMeta).length && !routeMeta?.requiresNoAuth;

    if (error.response) {
      if (error.response.data.error) {
        store.commit('global/showToast',{ isError: true, text: error.response.data.error });

      }else if (error.response.data.errors) {
        let message = "";
        if (error.response.data.errors instanceof Array)
          message = error.response.data.errors.join("<br />");
        else if (error.response.data.errors instanceof Object)
          message = Object.values(error.response.data.errors).join("<br />");
        store.commit('global/showToast',{ isError: true, text: message });

      }else if (error.response.data.meta instanceof Object && error.response.data.meta.errors) {
        let message = "";
        if (error.response.data.meta.errors instanceof Array)
          message = error.response.data.meta.errors.join("<br />");
        else if (error.response.data.meta.errors instanceof Object)
          message = Object.values(error.response.data.meta.errors).join("<br />");

        if (message !== 'Data Not Found' && message !== "permission_denied") {
          const requestUrl = error.config.url;
          const messageAuthorizationRequired = message === 'Необходимо авторизоваться' || message === 'Authorization required' || message === 'Você deve fazer login' || message === 'No autorizado'

          if(!requestUrl.includes('api/app/integrations') && !requestUrl.includes('/api/app/user/openid/')){
            if (
                !requestUrl.includes('/api/app/user/') ||
                !messageAuthorizationRequired ||
                !requestUrl.includes('api/app/integrations')
            ) {
              if (requiresAuth || !messageAuthorizationRequired) {
                store.commit('global/showToast',{ isError: true, text: message });
              }
            }
          }
        }
      }

      //не авторизован
      if (error.response.status == 401 && requiresAuth) {
        if(store.state.global.isFramed && location.pathname?.includes('amo_iframe/channels')){
          router.push({ name: "Login", query: {redirectTo: '/app/channels-amo'} });
        }else if(store.state.global.isFramed && location.pathname?.includes('amo_iframe/amo_link_managers')){
          router.push({ name: "Login", query: {redirectTo: '/app/link-managers-amo'} });
        }else{
          router.push({ name: "Login", query: { redirectTo: location.pathname } });
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axios