import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getShares: async (filtersStr) =>
        messCall(`/promotions?${filtersStr}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getConfig: async () =>
        messCall(`/chat/getChat/`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    createShare: async (data) =>
        messCall(`/promotions`, {method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    editShare: async (data) =>
        messCall(`/promotions/${data.id}`, {method: 'put', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    deleteShare: async (id) =>
        messCall(`/promotions/${id}`, {method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

}
